<template>
  <b-card-code>
    <h4>Result</h4>
    <b-row>
      <b-col md="4">
        <b-form-group>
          <b-card-text>
            <span>{{ $t('Select an exam') }} </span>
          </b-card-text>
          <select v-model="exam_type_id" class="form-control" name="classes_id">
            <option :value="null">Select one</option>
            <option v-for="(fee,key) in exam_types" :key="key" :value="fee.id">{{fee.name}}</option>
          </select>
        </b-form-group>
      </b-col>
    </b-row>
    <template v-if="exam_type_id">
      <b-row>
        <b-col md="4">
          <b-table-simple hover small caption-top responsive>
            <b-tbody>
              <b-tr>
                <b-th class="text-left">{{ $t('Student ID') }}</b-th>
                <b-th class="text-left">: {{ single_result.student?single_result.student.cadet_no:'TBD' }}</b-th>
              </b-tr>
              <b-tr>
                <b-th class="text-left">{{ $t('Class') }}</b-th>
                <b-th class="text-left">: {{ classByID(single_result.classes_id) }}</b-th>
              </b-tr>
              <b-tr>
                <b-th class="text-left">{{ $t('Version') }}</b-th>
                <b-th class="text-left">: {{ versionByID(single_result.version_id) }}</b-th>
              </b-tr>
              <b-tr>
                <b-th class="text-left">{{ $t('Shift') }}</b-th>
                <b-th class="text-left">: {{ shiftByID(single_result.shift_id) }}</b-th>
              </b-tr>
              <b-tr>
                <b-th class="text-left">{{ $t('Wing') }}</b-th>
                <b-th class="text-left">: {{ wingByID(single_result.wing_id) }}</b-th>
              </b-tr>
              <b-tr>
                <b-th class="text-left">{{ $t('Student Group') }}</b-th>
                <b-th class="text-left">: {{ student_groupByID(single_result.student_group_id) }}</b-th>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
        <b-col md="3"></b-col>
        <b-col md="5">
          <b-button variant="primary" type="submit" @click.prevent="downloadMarksheet">
            Download My Marksheet
          </b-button>
          <!-- <b-table-simple hover small caption-top responsive>
            <b-thead>
              <b-th class="text-center" :colspan="1">Interval(%)</b-th>
              <b-th class="text-center" :colspan="1">Grade Name</b-th>
              <b-th class="text-center" :colspan="1">Grade Point</b-th>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(grade,k) in grades" :key="k">
                <b-th class="text-center">{{ grade.mark_from+'-'+grade.mark_to }}</b-th>
                <b-th class="text-center">{{ grade.grade_name }}</b-th>
                <b-th class="text-center">{{ grade.gp }}</b-th>
              </b-tr>
            </b-tbody>
          </b-table-simple> -->
        </b-col>
      </b-row>
      <!-- form -->
      <b-table-simple hover small caption-top responsive>
        <colgroup>
          <col>
          <col>
        </colgroup>
        <colgroup>
          <col>
          <col>
          <col>
        </colgroup>
        <colgroup>
          <col>
          <col>
        </colgroup>
        <b-thead>
          <b-tr>
            <b-th class="text-center" :colspan="1">SL</b-th>
            <b-th class="text-left" :colspan="1">Subject</b-th>
            <b-th class="text-center" :colspan="1">Code</b-th>
            <b-th class="text-center" :colspan="1">F.Mark</b-th>
            <template v-for="(md, key) in mark_types">
              <b-th class="text-center" :key="key">{{ md }}</b-th>
              <b-th class="text-center" :key="key+'p'">C. {{md}}</b-th>
            </template>
            <b-th class="text-center">Total</b-th>
            <b-th class="text-center">G.Total</b-th>
            <b-th class="text-center">Grade</b-th>
            <b-th class="text-center">GP</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(sub, index) in subject_wise_mark_list" :key="index">
            <b-th class="text-center">{{ index + 1 }}</b-th>
            <b-th class="text-left">{{ sub.subject }} {{sub.is_optional?'(4th Subject)':''}}</b-th>
            <b-th class="text-center">{{ sub.code }}</b-th>
            <b-th class="text-center">{{ sub.total_mark }}</b-th>
            <template v-for="(md, key) in mark_types">
              <b-th :key="key" class="text-center">{{ findMark(sub.marks, md)? Math.ceil(findMark(sub.marks,
              md).obtain_mark): 'NA'
              }}</b-th>
              <b-th :key="key+'p'" class="text-center">{{findMark(sub.marks, md)?Math.ceil(findMark(sub.marks,
              md).converted_mark): 'NA' }}</b-th>
            </template>
            <b-td class="text-center">{{ Math.ceil(sub.obtained_mark) }}</b-td>

            <b-td v-if="sub.has_part && sub.is_part_active" rowspan="2" class="text-center">
              {{Math.ceil(sub.grand_total)}}</b-td>
            <b-td v-else-if="(sub.has_part===1 && sub.is_part_active ===0) || sub.has_part===0" rowspan="1"
              class="text-center">{{Math.ceil(sub.grand_total)}}
            </b-td>


            <b-td v-if="sub.has_part  && sub.is_part_active" rowspan="2" class="text-center">{{sub.grade}}
            </b-td>
            <b-td v-else-if="(sub.has_part===1 && sub.is_part_active ===0) || sub.has_part===0" rowspan="1"
              class="text-center">{{sub.grade}}</b-td>

            <b-td v-if="sub.has_part  && sub.is_part_active" rowspan="2" class="text-center">{{sub.gp}}</b-td>
            <b-td v-else-if="(sub.has_part===1 && sub.is_part_active ===0) || sub.has_part===0" rowspan="1"
              class="text-center">{{sub.gp}}</b-td>
          </b-tr>

        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td :colspan="((mark_types.length*2) + 4)" class="text-center">
              {{ $t('Total Marks ,Grade & GPA') }}
            </b-td>
            <b-td class="text-center">{{ Math.ceil(single_result.obtained_mark)}}</b-td>
            <b-td class="text-center"></b-td>
            <b-td class="text-center">
              {{jsonDecode2(single_result.others)?jsonDecode2(single_result.others).grade_name: 'F'}}</b-td>
            <b-td class="text-center">GPA
              {{jsonDecode2(single_result.others)? jsonDecode2(single_result.others).gp: 0.00}}</b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTableSimple, BThead, BTr, BTd, BTfoot, BTh, BTbody, BRow, BCol, BFormGroup, BCardText, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from "vuex";
import moment from "moment"
import apiCall from "@/libs/axios";
import download from "downloadjs";
export default {
  name: 'StudentResult',
  components: {
    BCardCode, BFormGroup, BCardText,
    BThead, BTr, BTd, BTfoot,
    BTh, BTbody, BRow, BCol, BButton,
    BTableSimple,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment,
      required,
      dir: false,
      exam_type_id: null,
      mark_types: [],
      single_result: {},
      grade_type: {},
      grades: [],
      results: [],
      exam_types: [],
      subject_wise_mark_list: [],
      mark_download_info: {}
    }
  },
  methods: {

    downloadMarksheet() {
      let data = new FormData();
      data.append("version_id", this.mark_download_info.version_id);
      data.append("shift_id", this.mark_download_info.shift_id);
      data.append("wing_id", this.mark_download_info.wing_id);
      data.append("classes_id", this.mark_download_info.classes_id);
      data.append("student_group_id", this.mark_download_info.student_group_id);
      data.append("student_id", this.mark_download_info.student_id);
      data.append("academic_year_id", this.mark_download_info.academic_year_id);
      data.append("exam_type_id", this.mark_download_info.exam_type_id);
      data.append("title", this.mark_download_info.title);

      apiCall
        .post("/download/student/marksheet", data, {
          responseType: "blob",
        })
        .then((response) => {
          const content = response.headers["content-type"];
          download(response.data, "student_marksheet.pdf", content);
        })
        .catch((error) => {
          this.$toaster.error(error.response.data.message);
        });
    },
    async getResults() {
      await apiCall.get('/student/personal/results').then((response) => {
        this.results = response.data;
      }).catch(() => {
        this.results = [];
      })
    },
    findExamTypes() {
      let temp_array = [];
      temp_array.push(...this.results.map(item => item.exam_type));

      temp_array.forEach((element) => {
        if (!this.exam_types.filter(e => e.id === element.id).length > 0) {
          const exam_type = {
            id:element.id,
            name:element.name
          }
          this.exam_types.push(exam_type);
        }
      }
      );
    },
    findResult() {
      let result_by_exam_type = this.results.find(item => item.exam_type_id === parseInt(this.exam_type_id));
      this.single_result = result_by_exam_type;
      if (result_by_exam_type) {

        this.mark_download_info.version_id = result_by_exam_type.version_id;
        this.mark_download_info.shift_id = result_by_exam_type.shift_id;
        this.mark_download_info.wing_id = result_by_exam_type.wing_id;
        this.mark_download_info.classes_id = result_by_exam_type.classes_id;
        this.mark_download_info.student_group_id = result_by_exam_type.student_group_id;
        this.mark_download_info.student_id = result_by_exam_type.student_id;
        this.mark_download_info.academic_year_id = result_by_exam_type.academic_year_id;
        this.mark_download_info.exam_type_id = parseInt(this.exam_type_id);
        this.mark_download_info.title = "Results";

        let result_array = this.jsonDecode(result_by_exam_type.results);
        var unique_result_list = [];

        result_array.forEach((element) => {
          if (!unique_result_list.filter(e => e.subject_id === element.subject_id).length > 0 && element.grand != null) {
            const student = {
              subject_id: element.subject_id,
              subject: element.subject,
              subject_short_name: element.subject_short_name,
              code: element.code,
              is_optional: element.is_optional,
              has_part: element.has_part,
              is_part_active: element.is_part_active,
              has_mark_effect: element.has_mark_effect,
              is_grade_subject: element.is_grade_subject,
              total_mark: element.total_mark,
              obtained_mark: element.obtained_mark,
              subject_pass_status: element.subject_pass_status,
              grand_total: element.grand.grand_total,
              highest_mark: element.grand.highest_mark,
              grade: element.grand.grade,
              gp: element.grand.gp,
              marks: element.marks
            };
            unique_result_list.push(student);
          }
        });
        this.subject_wise_mark_list = unique_result_list;
      }
      else {
        this.subject_wise_mark_list = {};
      }
    },

    findMark(mk, md) {
      let expected_mark = null;
      let mark_info = mk.find(item => item.name === md);

      if (mark_info) {
        expected_mark = mark_info;
      }
      else {
        expected_mark = null;
      }
      return expected_mark;
    },
    findMarkTypes() {
      let e_type = (this.single_result != undefined || this.single_result != null) ? this.exam_types.find(item => item.id === this.single_result.exam_type_id) : undefined;
      if (e_type) {
        this.mark_types = this.jsonDecode(e_type.mark_types);
      }
      else {
        this.mark_types = [];
      }
    },
    findGradeType() {
      let g_type = (this.single_result != undefined || this.single_result != null) ? this.single_result.grade_type : undefined;

      if (g_type) {
        this.grade_type = g_type;
      }
      else {
        this.grade_type = {};
      }

      this.findGrades();
    },
    findGrades() {
      this.grades = this.jsonDecode(this.grade_type.grades);
    },
    jsonDecode(data) {
      try {
        return JSON.parse(data);
      }
      catch (e) {
        return [];
      }
    },
    jsonDecode2(data) {
      try {
        return JSON.parse(data);
      }
      catch (e) {
        return null;
      }
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['classes', 'classByID', 'versions', 'versionByID', 'shifts', 'shiftByID', 'wings', 'wingByID',
      'student_groups', 'student_groupByID', 'academic_years', 'academic_yearByID',]),
  },
  created() {
    this.getResults();
    if (this.classes.length < 1) this.$store.dispatch('GET_ALL_CLASSES');
    // if (this.versions.length < 1) this.$store.dispatch('GET_ALL_VERSION');
    // if (this.shifts.length < 1) this.$store.dispatch('GET_ALL_SHIFT');
    // if (this.wings.length < 1) this.$store.dispatch('GET_ALL_WING');
    // if (this.student_groups.length < 1) this.$store.dispatch('GET_ALL_STUDENT_GROUP');
    // if (this.academic_years.length < 1) this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
  },
  watch: {
    results() {
      this.findExamTypes();
      this.findMarkTypes();
      //this.findGrades();
    },
    exam_type_id() {
      this.findResult();
      this.findMarkTypes();
    },
    single_result() {
      this.findGradeType();
    },
    grade_type() {
      this.findGrades();
    },
  }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>